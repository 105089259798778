#gate {
    width: 80%;
}

.overelem {
 width: 30em;
 margin: auto;
}

.albumCover {
    width: 20em;
}

.blur {
    width: 60em;
    height: 60em;
    max-width: 120%;
    margin: auto;
    top: 0px;
    filter: blur(32px) brightness(100%);
    -webkit-filter: blur(32px) brightness(100%);
    z-index: 1;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
}

.content {
    z-index: 2;
    position: absolute;
    width: 20em;
    top: 0px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
    background-color: rgb(18, 22, 27);
    margin-top: 150px;
    padding: 5px;
}

.jungle {
    font-family: "JungleFever";
    color: #EEE;
    font-size: 32px;
    margin-top: 8px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

.platforms tr img {
    height: 2em;
}

.platforms tr img:hover {
    cursor: pointer;
}

.platforms tr {
    border-top: solid 1px rgb(61, 73, 90);
}

.listenBtn {
    border: 2px solid #EEE;
    padding: 12px;
    margin: 8px;
    color: #EEE;
    font-family: "Arial";
    font-size: 14px;
    margin-bottom: 10px;
}

.listenBtn:hover {
    cursor: pointer;
    background-color: rgb(31, 37, 46);
}

#banner {
    position: absolute;
    z-index: 3;
    top: 200px;
    background-color: rgb(29, 32, 39);
    color: #EEE;
    font-family: Arial;
    font-size: 16px;
    width: 25em;
    max-width: 95%;
    height: 20em;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
    font-size: 18px;
}

#banner p {
    margin: 1em;
}

#banner .title { 
    text-align: left;
    background-color: rgb(20, 20, 20);
    margin-top: 0px;
    height: 50px;
    padding: 5px;
}

#banner h3 {
    margin-left: 10px;
    margin-top: 5px;
    font-size: 23px;
    line-height: 40px;
}

#banner button {
    background-color: rgb(29, 32, 39);
    font-family: Alata;
    font-size: 16px;
}

#banner button:hover {
    filter: brightness(80%);
    cursor: pointer;
}

.accept {
    background-color: #1DB954 !important;
    width: 90%;
    height: 5em;
    margin-top: 2em;
    border: none;
    border-radius: 10px;
    color: #EEE;
}

.decline {
    position: absolute;
    left: 6px;
    bottom: 6px;
    border: 0px;
    color: #AAA;
}