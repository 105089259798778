html {
  background-color: black;
}

body {
  background-color: #000;
  overflow-x: hidden;
  max-width: 100%;
}

@font-face {
  font-family: "Alata";
  src: url(./Fonts/alata-regular.ttf) format("truetype");
}

@font-face {
  font-family: 'JungleFever';
  src: url('./Fonts/JungleFever.eot');
  src: url('./Fonts/JungleFever.eot?#iefix') format('embedded-opentype'),
      url('./Fonts/JungleFever.woff2') format('woff2'),
      url('./Fonts/JungleFever.woff') format('woff'),
      url('./Fonts/JUNGLEFE.TTF') format('truetype'),
      url('./Fonts/JungleFever.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

.footer {
  position: fixed;
  height: 80px;
  bottom: 0px;
  background-color: black;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
  max-width: 80%;
}

.leaf {
  position: fixed;
  width: 20vw;
  height: 20vw;
  border-radius: 100% 0%;
  overflow-x: hidden;
  z-index: 100;
}

.leaf-left {
  transform-origin: bottom left;
  top: -20vw;
  left: 0px;
  filter: drop-shadow(0px -10px 8px #000);
}

.leaf-right {
  transform-origin: top right;
  top: 0px;
  right: 0px;
  filter: drop-shadow(0px 10px 8px #000);
}

.logoholder {
  margin-top: 70px;
  height: 30vw;
  overflow: hidden;
}

#logo {
  opacity: 1;
  animation-name: fadeIn;
  animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
  height: 28vw;
}

#navmenu {
  color: white;
  font-family: 'JungleFever';
  width: 40vw;
  margin: auto;
}

.navbtn {
  cursor: pointer;
  display: inline-block;
  margin-left: 12px;
}

.navelem {
  height: 30px;
}

.navelem, .navelem div {
  display: inline-block;
}

.navelem div {
  margin-left: 10px;
}

.navelem div p {
  margin-top: 10px;
  margin-bottom: 10px;
}

.selected-navelem {
  color: orange;
}

.textField {
  font-family: 'Alata';
  font-size: 18px;
  color: white;
  width: 40vw;
  max-width: 90%;
  text-align: center;
  margin: auto;
  margin-top: 40px;
  opacity: 1;
  margin-bottom: 100px;
}

.galleryField {
  width: 40vw;
  margin: auto;
  margin-top: 40px;
}

@media only screen and (min-width: 0px) and (max-width: 750px) { 
  #navmenu {
    font-size: 4vw;
  }

  .extrabar-1, .extrabar-2{
    display: inline-block !important;
  }

  .textField, .galleryField {
    width: 95vw;
  }
}

@media only screen and (min-width: 750px) and (max-width: 1200px) { 
  #navmenu {
    font-size: 3vw;
  }

  .extrabar-1{
    display: inline-block !important;
  }
  .extrabar-2 {
    display: none !important;
  }
  .textField, .galleryField {
    width: 55vw;
  }
}

@media only screen and (min-width: 1200px) { 
  #navmenu {
    font-size: 1.5vw;
  }
  .extrabar-1, .extrabar-2{
    display: none !important;
  }
}

#social-medias {
  max-width: 100%;
}

#social-medias img{
  max-height: 48px;
  max-width: 6%;
  margin: 10px;
}

.fadeIn {
  opacity: 1;
  animation-name: fadeIn;
  animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 4s;
}

.fadeInFast {
  opacity: 1;
  animation-name: fadeInFast;
  animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1.5s;
}

.fadeInSuperFast {
  opacity: 1;
  animation-name: fadeInFast;
  animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;
}

.fadeInSlow {
  opacity: 1;
  animation-name: fadeInFast;
  animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 5s;
}

@keyframes fadeIn{
	0% {
		opacity: 0;
	}
  33% {
    opacity: 0;
  }
	100% {
		opacity: 1;
	}
}

@keyframes fadeInFast{
	0% {
		opacity: 0;
	}
  50% {
    opacity: 0;
  }
	100% {
		opacity: 1;
	}
}

@keyframes fadeInSuperFast{
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}


.logoFadeIn {
  animation-name: logofadeIn; 
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

.logoFadeOut {
  animation-name: logofadeOut; 
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}


@keyframes logofadeIn{
	0% {
		opacity: 0;   
    height: 0px;
	}
  50% {
    opacity: 0;
    height: 30vw;
  }
	100% {
    opacity: 1;
    height: 30vw;
	}
}

@keyframes logofadeOut{
	0% {
		opacity: 1;
    height: 30vw;
	}
  50% {
    opacity: 0;
    height: 30vw;
  }

	100% {
		opacity: 0;   
    height: 0px;
	}
}
a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.albumart {
  width: 250px;
  height: 250px;
  display: inline-block;
}

#spotifyBtn {
  border-radius: 5px;
  background-color: #1DB954;
  width: 12em;
  margin: auto;
  padding: 8px;
  font-size: 24px;
}

#spotifyBtn:hover {
  cursor: pointer;
  background-color: #167a39;
}

#spotifyBtn img {
  height: 24px;
  margin-left: 8px;
}